// extracted by mini-css-extract-plugin
export var masthead = "Masthead-module--masthead--eI-uu";
export var title = "Masthead-module--title--1TNtX";
export var showFadeInUp = "Masthead-module--show-fade-in-up--oQNZ_";
export var description = "Masthead-module--description--1TmWC";
export var mastheadCover = "Masthead-module--mastheadCover--1n46c";
export var imageContainer = "Masthead-module--imageContainer--18W3G";
export var image = "Masthead-module--image--LJyDT";
export var show = "Masthead-module--show--1vd2Z";
export var mastheadTitleMobileSmall = "Masthead-module--mastheadTitleMobileSmall--hKlmg";
export var offsetLarge = "Masthead-module--offsetLarge--1omOG";
export var showFadeInDown = "Masthead-module--show-fade-in-down--3x-ME";
export var hideFadeOut = "Masthead-module--hide-fade-out--3W3wE";
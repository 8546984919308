import React from 'react';
import { Content } from 'patterns/Content';
import { Masthead } from 'patterns/Masthead';
import { Shell } from 'patterns/Shell';

import '../scss/main.scss';

function PrivacyPage({
    location: {
        pathname,
    },
}) {
    const data = {
        masthead: {
            title: 'Privacy',
            description: [
                {
                    spans: [],
                    text: 'This policy explains how I collect & treat any information you give me',
                    type: 'paragraph',
                },
            ],
            image: {
                alt: '',
                dimensions: {
                    height: 4000,
                    width: 6000,
                },
                url: 'https://images.prismic.io/robsimpson/ac246b06-5001-4d48-bef2-03838a3b1e99_privacy.jpg?auto=compress,format'
            },
        },
    };

    return (
        <Shell
            pageDescription=""
            pageSocialImage=""
            pageTitle="Privacy Policy"
            pathname={pathname}
        >
            <Masthead
                description={data.masthead.description}
                image={data.masthead.image}
                title={data.masthead.title}
            />
            <div className="container">
                <Content
                    content={[
                        {spans: [], text: "Why I value your privacy", type: "heading2"},
                        {spans: [], text: "I value your privacy as much as I do my own, so I’m committed to keeping your personal and business information safe.", type: "paragraph"},
                        {spans: [], text: "I’m uncomfortable with the information companies, governments, and other organisations keep on file, so I ask for only the bare minimum from my users and customers.", type: "paragraph"},
                        {spans: [], text: "I’ll never use your personal information for any reason other than why you gave it, and I’ll never give anyone access to it unless I’m forced to by law.", type: "paragraph"},
                        {spans: [], text: "How I collect information", type: "heading2"},
                        {spans: [], text: "I collect your email address when you sign up for my newsletter", type: "list-item"},
                        {spans: [], text: "I ask for your account and contact information when you hire me", type: "list-item"},
                        {spans: [], text: "What information I hold", type: "heading2"},
                        {spans: [], text: "If you sign up for my newsletter, I only collect your email address and name", type: "list-item"},
                        {spans: [], text: "If you do business with me, I also collect your business name and bank details and keep records of the invoices I send to you and the payments you make.", type: "list-item"},
                        {spans: [], text: "Where I store your information", type: "heading2"},
                        {spans: [], text: "If you sign up for my newsletter, I store your email address in Mailchimp, which is the marketing platform I prefer.", type: "paragraph"},
                        {spans: [], text: "When you contact me by email or through my website, I don’t currently store your data anywhere.", type: "paragraph"},
                        {spans: [], text: "I chose these systems partly for their commitment to security.", type: "paragraph"},
                        {spans: [], text: "What I use your information for", type: "heading2"},
                        {spans: [], text: "I occasionally use your contact information to send you updates about new articles I’ve created.", type: "paragraph"},
                        {spans: [], text: "When I do, you have the option to unsubscribe from these communications and I won’t send them to you again.", type: "paragraph"},
                        {spans: [], text: "I might also email or phone you about my services, but if you tell me not to, I won’t get in touch again.", type: "paragraph"},
                        {spans: [], text: "I will also use your information to send you invoices, statements, or reminders.", type: "paragraph"},
                        {spans: [], text: "Who’s responsible for your information", type: "heading2"},
                        {spans: [], text: "Rob Simpson (me), is responsible for the security of your information. You can contact me by email at hello@robsimpson.digital if you have any concerns about the information I store.", type: "paragraph"},
                        {spans: [], text: "Who has access to information about you", type: "heading2"},
                        {spans: [], text: "When I store information in my own systems, only I have access.", type: "paragraph"},
                        {spans: [], text: "The steps I take to keep your information private", type: "heading2"},
                        {spans: [], text: "Where I store your information in third-party services, access is restricted to myself.", type: "paragraph"},
                        {spans: [], text: "The computer I use is protected by passcode access. This computer asks for authentication whenever it’s started or after 5 minutes of inactivity. My mobile device is also protected by a fingerprint.", type: "paragraph"},
                        {spans: [], text: "How to complain", type: "heading2"},
                        {spans: [], text: "I take complaints very seriously. If you’ve any reason to complain about the ways I handle your privacy, please contact me (Rob Simpson) by email at hello@robsimpson.digital.", type: "paragraph"},
                        {spans: [], text: "Changes to the policy", type: "heading2"},
                        {spans: [], text: "If I change the contents of this policy, those changes will become effective the moment I publish them on my website.", type: "paragraph"},
                    ]}
                />
            </div>
        </Shell>
    );
}

export default PrivacyPage;

import React from 'react';

import { Content as ContentComponent } from 'components/Content';
import { Grid } from 'components/Grid';
import { cx } from 'utility';
import * as styles from './Content.module.scss';

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['content'],
    );

    return classes || undefined;
}

function Content({
    className,
    content,
    gridClassName,
}) {
    const classes = getClasses({ className });

    return (
        <div className={classes}>
            <Grid className={gridClassName}>
                <div className={styles['container']}>
                    <ContentComponent content={content} />
                </div>
            </Grid>
        </div>
    );
}

export { Content };

import React from 'react';
import PropTypes from 'prop-types';

import { Content } from 'components/Content';
import { Image } from 'components/Image';
import { Grid } from 'components/Grid';
import { Heading } from 'components/Heading';
import { cx, capitalizeFirstLetter } from 'utility';
import * as styles from './Masthead.module.scss';

const propTypes = {
    className: PropTypes.string,
    description: PropTypes.array,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    title: PropTypes.string,
    titleSmall: PropTypes.bool,
    titleMobileSmall: PropTypes.bool,
};

const defaultProps = {
    className: '',
    description: [],
    image: {},
    title: '',
    titleSmall: false,
    titleMobileSmall: false,
};

function getClasses({ className, mastheadType, subheader, titleMobileSmall }) {
    const classes = cx(
        className,
        styles['masthead'],
        styles[`masthead${capitalizeFirstLetter(mastheadType)}`],
        titleMobileSmall && styles[`mastheadTitleMobileSmall`],
        subheader && styles['offsetLarge'],
    );

    return classes || undefined;
}

function Masthead({
    className,
    description,
    image,
    subheader,
    title,
    titleSmall,
    titleMobileSmall,
}) {
    const mastheadType = (!image.url) ? 'content' : 'cover';
    const classes = getClasses({ className, mastheadType, subheader, titleMobileSmall });
    let titleFontSize = (mastheadType === 'content') ? 10 : 13;

    if (titleSmall) { titleFontSize = 11; }

    return (
        <div className={classes}>
            <Grid>
                {image.url && (
                    <div className={styles['imageContainer']}>
                        <Image
                            className={styles['image']}
                            image={image}
                            size="xlarge"
                        />
                    </div>
                )}
                {title && (
                    <Heading
                        className={styles['title']}
                        size={titleFontSize}
                    >
                        {title}
                    </Heading>
                )}
                {description && (
                    <Content
                        className={styles['description']}
                        content={description}
                    />
                    // <Text
                    //     as="p"
                    //     className={styles['masthead__description']}
                    //     size={6}
                    // >
                    //     {description}
                    // </Text>
                )}
            </Grid>
        </div>
    );
}

Masthead.propTypes = propTypes;
Masthead.defaultProps = defaultProps;

export { Masthead };
